






















































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import EnumUtils from "src/utils/enum-utils";
import { RELATION, ID_TYPE, MARRIAGE_STATUS } from "src/enums";
import { Toast, Dialog } from "vant";
import patterns from "src/utils/patterns";
import { FamilyService } from "src/services";
import dayjs from "dayjs";

/**
 * 常用联系人表单
 * @class
 * @version 1.0.0
 */
@Component
export default class Contacts extends Vue
{
    /**
     * 联系人数据模型
     * @private
     *
     */
    private modelData: any =
    {
        id:null,
        memberType: 2,
        memberName:"", // 用户姓名
        familyType: null, // 用户关系
        familyTypeName: "",// 用户关系名称
        phone: "", // 手机号
        sex: 0, // 用户性别
        birthday: null, // 生日
        idTypeName: null,
        identityCardType: null, // 证件类型
        marriageStatus: 1, // 用户婚姻状态
        cardNo: "", // 证件号
        remark: "" // 备注
    };

    /**
     * 日期控件
     * @private
     * @returns Date
     */
    private birthdayDate: Date = dayjs().toDate();

    /**
     * 日期控件
     * @private
     * @returns Date
     */
    private minDate: Date = new Date(1900, 1, 1);

    /**
     * 最大时间
     * @private
     * @returns Date
     */
    private maxDate: Date = dayjs().toDate();

    /**
     * 是否显示本人关系弹框
     * @private
     * @returns boolean
     */
    private showRelationPicker: boolean = false;

    /**
     * 出生日期弹框
     * @private
     * @returns boolean
     */
    private showDate: boolean = false;

    /**
     * 是否加载
     * @private
     * @returns boolean
     */
    private loading: boolean = false;

    /**
     * 显示身份证类型table
     * @private
     * @returns boolean
     */
    private showIdtypePicker: boolean = false;

    /**
     * 验证是否正确手机号
     * @private
     * @returns void
     */
    private validatorPhone(val: any): any
    {
        return patterns.mobile.test(val);
    }

    /**
     * 与本人关系数据集合
     * @private
     * @returns Array<any>
     */
    private get relationshipList(): Array<any>
    {
        let relationshipList = EnumUtils.getEntries(RELATION);

        return relationshipList;
    }

    /**
     * 身份证类型数据集合
     * @private
     * @returns Array<any>
     */
    private get idTypeList(): Array<any>
    {
        return EnumUtils.getEntries(ID_TYPE);
    }

    /**
     * 结婚状态集合
     * @private
     * @returns Array<any>
     */
    private get marriageStatusList(): Array<any>
    {
        return EnumUtils.getEntries(MARRIAGE_STATUS);
    }

    /**
     * 用户id
     * @private
     * @returns string
     */
    private get userTd(): string
    {
        return this.$route.query && (this.$route.query.userId as string) || "";
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 删除人员
     * @private
     * @returns void
     */
    private onDelMember(): any
    {
        let self: any = this;
        Dialog.confirm({
        title: "删除家属",
        message: "是否确定删除该家属"
        })
        .then(async() => {
            try
            {
                let {content: result} =  await FamilyService.instance.deleteFamilyMember(this.modelData.id);
                
                Toast({
                    duration: 1000,
                    message: "删除成功"
                });
                self.$router.replace({name:"familyList"});
                self.showDate = false;
            }
            catch(err)
            {
                Toast(JSON.stringify(err));
            }
        });
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        if(this.userTd)
        {
            this.getUserDetail(+this.userTd);
        }
    }

    /**
     * 确定日期选择
     * @private
     * @returns void
     */
    private onDateConfirm(date: any): void
    {
        if(date)
        {
            this.modelData.birthday = dayjs(date).format("YYYY/MM/DD");
        }
        
        this.showDate = false;
    }

    /**
     * 取消日期选择
     * @private
     * @returns void
     */
    private onDateCancel(): void
    {
        this.showDate = false;
    }

    /**
     * 获取身份类型
     * @private
     * @returns string
     */
    private getIdTypeName(value: number): string
    {
        return EnumUtils.getEntry(value, ID_TYPE) && EnumUtils.getEntry(value, ID_TYPE).description;
    }

    /**
     * 获取关系
     * @private
     * @returns string
     */
    private getRelationName(value: number): string
    {
        return EnumUtils.getEntry(value, RELATION) && EnumUtils.getEntry(value, RELATION).description;
    }

    /**
     * 获取用户详情
     * @private
     * @returns Promise<void>
     */
    private async getUserDetail(userId: number): Promise<void>
    {
        try
        {
            let {content: result} =  await FamilyService.instance.getFamilyDetail(userId);

            if(result.data)
            {
                this.modelData =
                {
                    id: result.data.id,
                    memberType: result.data.memberType,
                    memberName: result.data.memberName, // 用户姓名
                    familyType: result.data.familyType, // 用户关系
                    familyTypeName: this.getRelationName(result.data.familyType),// 用户关系名称
                    phone: result.data.phone, // 手机号
                    sex: result.data.sex, // 用户性别
                    birthday: dayjs(result.data.birthday).format("YYYY/MM/DD"), // 生日
                    identityCardType: result.data.identityCardType, // 证件类型
                    idTypeName: this.getIdTypeName(result.data.identityCardType),
                    marriageStatus: result.data.marriageStatus, // 用户婚姻状态
                    cardNo: result.data.cardNo, // 证件号
                    remark: result.data.remark // 备注
                };

                this.birthdayDate = dayjs(result.data.birthday).toDate();
            }

        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 确定选择与本人关系
     * @private
     * @returns void
     */
    private onRelationConfirm(data: any): void
    {
       this.modelData.familyTypeName = data.description;
       this.modelData.familyType = data.value;
       this.showRelationPicker = false;
    }

    /**
     * 确定选择身份证类型
     * @private
     * @returns void
     */
    private onIdTypeConfirm(data: any): void
    {
       this.modelData.idTypeName = data.description;
       this.modelData.identityCardType = data.value;
       this.showIdtypePicker = false;
    }
    
    /**
     * 显示关系选项
     * @private
     * @returns void
     */
    private onShowRelation(): void
    {
        this.showRelationPicker = true;
    }

    /**
     * 保存常用联系人
     * @private
     * @returns void
     */
    private async onSubmitContacts(): Promise<void>
    {
       
        // 验证表单数据
        if(!this.loading)
        {
            
            this.loading = true;
            try
            {

                let {content: result} =  await FamilyService.instance.modifyFamily(this.modelData);

                Toast.success("保存成功!");
                this.$router.go(-1);
            }
            catch(err)
            {
                Toast(JSON.stringify(err));
            }
            finally
            {
                this.loading = false;
            }
        }
    }

}
