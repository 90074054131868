























import { Component, Vue } from "vue-property-decorator";
import { FamilyService } from "src/services";
import EnumUtils from "src/utils/enum-utils";
import { RELATION } from "src/enums";
import { Toast,Dialog } from "vant";

@Component
export default class OfteUser extends Vue
{
    /**
     * 家属数据列表
     * @private
     * @returns any
     */
    private familyList: Array<any> = [];


    /**
     * 获取与本人关系
     * @private
     * @returns string
     */
    private getRelationship(value: number): string
    {
        return EnumUtils.getEntry(value, RELATION) && EnumUtils.getEntry(value, RELATION).description;
    }

    /**
     * 跳转修改体检用户家属数据列表
     * @private
     * @returns void
     */
    private updataFamilyInfo(item): void
    {
        this.$router.push({name: "family-operation",query: {userId: item.id}});
    }

    /**
     * 获取体检用户家属数据列表
     * @private
     * @returns void
     */
    private async checkFamilyList(): Promise<void>
    {
        try
        {
            let {content: result} =  await FamilyService.instance.getFamilyList();

            if(result.data)
            {
                this.familyList = result.data;
            }

        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        this.checkFamilyList();
    }

}
